import React from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useRoutes } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import LibUiGlobalStyle from '@nickel/foundations/components/config/GlobalStyle'
import nickel from '@nickel/foundations/themes'

import { queryConfig } from './app/config/query'
import { routes } from './app/routes'
import Styled from './styles'

const queryClient = new QueryClient(queryConfig)

const Router = () => useRoutes(routes)

const App = () => (
    <ThemeProvider theme={nickel}>
        <QueryClientProvider client={queryClient}>
            <LibUiGlobalStyle />
            <Styled.Global />
            <Router />
        </QueryClientProvider>
    </ThemeProvider>
)

export default App
