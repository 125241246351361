import React, { Suspense } from 'react'

import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import i18n from './app/i18n'

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Suspense fallback={null}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Suspense>
    </I18nextProvider>,
    document.getElementById('root')
)
