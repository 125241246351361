import { initReactI18next } from 'react-i18next'

import { addLibTranslations as addLibCoreUiTranslations } from '@nickel/core-ui/lib/i18n'
import i18n, { addTranslations, Dictionnary } from '@nickel/i18n'
import { addLibTranslations as addLibL10nTranslations } from '@nickel/l10n/lib/i18n'

import root from './languages/root'

i18n.use(initReactI18next).init({ fallbackLng: 'root', lng: 'en-US' })

export const dictionnary: Dictionnary = {
    root
}

addTranslations(dictionnary)
addLibCoreUiTranslations()
addLibL10nTranslations()

export default i18n
