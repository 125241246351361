import React, { lazy } from 'react'

import { Navigate, RouteObject } from 'react-router-dom'

const Layout = lazy(() => import('../layout'))
const CustomersStatsDashboard = lazy(() => import('../screens/CustomersStatsDashboard'))

export const routes: RouteObject[] = [
    {
        element: <Layout />,
        children: [
            {
                path: '/customers-stats-dashboard',
                element: <CustomersStatsDashboard />
            }
        ]
    },
    {
        path: '*',
        element: <Navigate to="/customers-stats-dashboard" />
    }
]
